import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from "gatsby";
import { BigText } from '../common';
import Sponsor from './sponsor';

const Wrapper = styled.div`
    padding: 20px;
    max-width: 1300px;
`
const PartnerWrapper = styled.div`
    margin-top: 50px;
    display: grid;
    grid-gap: 50px;
    grid-template-columns: auto auto auto;
    padding: 20px;
    @media (max-width: 1150px) {
        grid-template-columns: auto auto;
    }
    @media (max-width: 600px) {
        grid-template-columns: auto;
    }
`

const SponsorenListe = () => {

    const data = useStaticQuery(graphql`
        query SponsorenQuery {
            allFile(filter: {sourceInstanceName: {eq: "sites"}, name: {eq: "partner"}}) {
            edges {
                node {
                childMarkdownRemark {
                    frontmatter {
                        title
                        partnerList {
                        bild {
                            childImageSharp {
                                gatsbyImageData(width: 500)
                            }
                        }
                        partnerType
                        title
                        website
                        }
                    }
                }
                }
            }
            }
        }
    `)

    const sponsoren = data.allFile.edges[0].node.childMarkdownRemark.frontmatter;

    const renderPartner = () => {
        const partnerArr = [];
        sponsoren.partnerList.forEach((sponsor) => {
            partnerArr.push(
                <Sponsor 
                    bild={sponsor.bild} 
                    title={sponsor.title} 
                    partnerType={sponsor.partnerType} 
                    website={sponsor.website} 
                />
            );
        })
        return partnerArr;
    }

    return (
        <Wrapper>
            <BigText>{sponsoren.title}</BigText>
            <PartnerWrapper>
                {renderPartner()}
            </PartnerWrapper>
        </Wrapper>
    )
};

export default SponsorenListe;