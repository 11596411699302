import React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Wrapper = styled.a`
    text-decoration: none;
    color: ${props => props.theme.colors.white};
    background: ${props => props.theme.colors.gray1};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`
const Padding = styled.div`
    padding: 10px;
`

const Title = styled.p`
    font-family: ${props => props.theme.fonts.normal};
    color: ${props => props.theme.colors.darkGray};
    font-size: 1.3em;
    padding: 10px 5px;
    text-align: center;
`
const SubTitle = styled.p`
    font-family: ${props => props.theme.fonts.normal};
    font-size: 1.3em;
    padding: 10px;
    text-align: center;
    background: ${props => props.theme.colors.darkGray};
    color: ${props => props.theme.colors.white};
`

const Sponsor = ({ bild, partnerType, title, website }) => {
    return (
        <Wrapper href={`https://${website}`} target="_blank">
            <Padding>
                <GatsbyImage image={getImage(bild)} alt={title} />
                <Title>{title}</Title>
            </Padding>
            <SubTitle>{partnerType}</SubTitle>
        </Wrapper>
    )
};

export default Sponsor;
