import * as React from "react";
import SiteWrapper from '../components/siteWrapper';
import Nav from '../components/sponsoren/nav';
import SponsorenListe from '../components/sponsoren/sponsorenListe';

const IndexPage = (props) => {
    return (
        <SiteWrapper title="Sponsoren - SV Kappel" description="Hier ein Überblick unserer Partner und Unterstützer des SV Kappel. Allen ein herzlichens Dankeschön.">
            <Nav />
            <SponsorenListe />
        </SiteWrapper>
    );
}

export default IndexPage;
